.custom-handle {
    position: absolute;
    right: -5px;
    top: 0;
    bottom: 0;
    width: 10px;
    cursor: col-resize;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.custom-handle:hover {
    background-color: rgba(0, 0, 0, 0.15);
}

.custom-handle::after {
    content: '⋮';
    /* Vertical ellipsis character */
    color: #999;
    font-size: 20px;
    line-height: 1;
    transition: color 0.3s ease;
}

.custom-handle:hover::after {
    color: #666;
}

.react-resizable {
    position: relative;
}